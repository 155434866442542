import { useIntl } from 'react-intl';
import * as localizations from 'modules/i18n/localizations';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';

export function useTranslate() {
    const intl = useIntl();

    const _formatMessage = (
        descriptor: Descriptor | string,
        values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
    ): string => {
        const _descriptor: Descriptor = {
            values: values || {},
            ...(typeof descriptor === 'string' ? { id: descriptor } : descriptor),
        };

        // Show default 'en' translation or text key
        if (!{}.hasOwnProperty.call(descriptor, 'defaultMessage')) {
            const defaultENMessage = localizations['en'][_descriptor.id];
            _descriptor.defaultMessage = defaultENMessage || _descriptor.id;
        }

        const { values: valuesDescriptor, ...restDescriptor } = _descriptor;

        return `${intl.formatMessage(restDescriptor, valuesDescriptor)}`;
    };

    return [_formatMessage];
}

export type Descriptor = {
    id: string;
    [key: string]: any;
};
