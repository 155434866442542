export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOG_OUT_ACTION = 'LOG_OUT_ACTION';

export const TEXTS = {
  title: { id: 'modules.login.title' },
  subtitle: { id: 'modules.login.subtitle' },
  login: { id: 'modules.login.btn.login' },
  loginWithFB: { id: 'modules.login.btn.fb' },
  loginWithGoogle: { id: 'modules.login.btn.google' },
  rememberMe: { id: 'modules.login.field.btn.remember-me' },
  goToRegisterPage: { id: 'modules.login.field.btn.go_to_register_page' },
  goToResetPasswordPage: { id: 'modules.login.btn.forgot_password' },
  placeholders: {
    email: { id: 'modules.login.field.email.placeholder' },
    password: { id: 'modules.login.field.password.placeholder' },
  },
};
