import Table from 'components/table';

const WebmasterReport = () => {

    return <div>
        <div className="title">Advertising report</div>
        <div className="content">
            <Table
                url="api/v1/webmasters"
                structure={['id', 'name', 'platform', 'api_key','isActive', 'createdAt', 'updatedAt']}
            />
        </div>
    </div>;
}


export default WebmasterReport;
