import { WrappedComponentProps } from 'react-intl';

export enum LOGIN_FORM_KEYS {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export interface ILoginProps extends WrappedComponentProps {
  changeLanguage: (value: string) => void;
  login: (data: LoginPayload) => void;
}

export interface ILoginState {
  [LOGIN_FORM_KEYS.EMAIL]: {
    value: string,
    error: string,
    validationList: string[]
  };
  [LOGIN_FORM_KEYS.PASSWORD]: {
    value: string,
    error: string,
    showPassword: boolean;
    validationList: string[]
  };
}

export interface LoginPayload {
  email: string;
  password: string;
}
