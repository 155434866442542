import { SET_USER_DATA } from './user.constants';
import { LOG_OUT_ACTION } from 'modules/login/login.constants';
import { isAuthenticated } from 'helper/auth';
import IUser from 'types/user';

interface UserAction {
  type: string;
  payload?: any;
}

const initialState: IUser = {
  profile: null,
  isAuthUser: isAuthenticated(),
};

export default function userReducer(state = initialState, action: UserAction): IUser {
  switch (action.type) {
    case LOG_OUT_ACTION:
      return {
        ...state,
        isAuthUser: false,
        profile: null,
      };
    case SET_USER_DATA:
      return {
        ...state,
        isAuthUser: true,
        profile: action.payload,
      };
    default:
      return state;
  }
}
