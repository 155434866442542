export default function GoogleSvg({ width = '18', height = '18' }: IGoogleSvg) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 18 18"
    >
      <g
        id="google"
        transform="translate(0 0)"
      >
        <path
          id="circuit_54"
          data-name="circuit 54"
          d="M251.492,198.108a8.977,8.977,0,0,0-.158-1.681.527.527,0,0,0-.518-.428h-8.289a.527.527,0,0,0-.527.527v3.164a.527.527,0,0,0,.527.527h4.253a4.722,4.722,0,0,1-1.059,1.393l2.981,2.981A8.932,8.932,0,0,0,251.492,198.108Z"
          transform="translate(-233.492 -189.108)"
          fill="#222"
        />
        <path
          id="circuit_55"
          data-name="circuit 55"
          d="M52.093,4.219a4.7,4.7,0,0,1,2.846.951.527.527,0,0,0,.69-.049l2.25-2.25a.528.528,0,0,0-.034-.777A8.955,8.955,0,0,0,44.663,3.953l3.1,3.1A4.747,4.747,0,0,1,52.093,4.219Z"
          transform="translate(-43.093)"
          fill="#222"
        />
        <path
          id="circuit_56"
          data-name="circuit 56"
          d="M52.093,318.549a8.906,8.906,0,0,0,5.365-1.8l-3.036-3.036a4.724,4.724,0,0,1-6.663-2.213l-3.1,3.1A8.963,8.963,0,0,0,52.093,318.549Z"
          transform="translate(-43.093 -300.549)"
          fill="#222"
        />
        <path
          id="circuit_57"
          data-name="circuit 57"
          d="M4.3,145.1a3.8,3.8,0,0,1,0-1.519L.986,140.271a8.883,8.883,0,0,0,0,8.137Z"
          transform="translate(0 -135.34)"
          fill="#222"
        />
      </g>
    </svg>
  );
}

interface IGoogleSvg {
  width?: string;
  height?: string;
}

