import Login from 'modules/login';
import BaseLayout from 'components/base';

export default function LoginPage() {
    return (
        <BaseLayout>
            <Login />
        </BaseLayout>
    );
}
