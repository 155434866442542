import { isFunction } from 'lodash';
import {
  INVALID,
  REQUIRED,
  IS_CHECKED,
  IS_EMAIL,
  // ON_EMAIL_CONFIRM_CHECKING,
  // ON_PASSWORD_CHECKING,
  // ON_PASSWORD_CONFIRM_CHECKING,
  // ON_PHONE_CHECKING,
  REG_EXP_EMAIL,
  // REG_EXP_NEW_USER_PASSWORD,
  // REG_EXP_PHONE_NUMBER,
} from './form-validations.constants';

export const onRequired = (value: string = '') => value.toString().trim().length > 0;
export const onRegExpChecking = (value: string, regExp: RegExp) => regExp.test(value);
export const onChecked = (value: boolean) => Boolean(value);

const validationMap: { [key: string]: Function } = {
  [REQUIRED]: onRequired,
  [IS_CHECKED]: onChecked,
  [IS_EMAIL]: (value: string) => onRegExpChecking(value, REG_EXP_EMAIL),
  //   [ON_EMAIL_CONFIRM_CHECKING]: (email_confirmation, email) => {
  //     return emailConfirmChecking(email_confirmation, email);
  //   },
  // TODO
  //   [ON_PASSWORD_CONFIRM_CHECKING]: onPasswordConfirmChecking,
  //   [ON_PASSWORD_CHECKING]: (value) => onRegExpChecking(value, { regExp: REG_EXP_NEW_USER_PASSWORD }),
  //   [ON_PHONE_CHECKING]: (value) => onRegExpChecking(value, { regExp: REG_EXP_PHONE_NUMBER }),
};

const validationErrorMap: { [key: string]: string } = {
  [INVALID]: 'services.form-validation.error.invalid',
  [REQUIRED]: 'services.form-validation.error.required',
  [IS_CHECKED]: 'services.form-validation.error.required',
  [IS_EMAIL]: 'services.form-validation.error.email.invalid',
};

const validateRunner = (rule: string, val: string) => {
  const isValid = isFunction(validationMap[rule]) ? validationMap[rule](val) : true;
  if (!isValid) {
    return validationErrorMap[rule] ? validationErrorMap[rule] : validationErrorMap[INVALID];
  }

  return !isValid && validationErrorMap[rule] ? validationErrorMap[rule] : '';
};

export const validate = (val: string | number, rules: string[]) => {
  const value = val.toString();
  let errorKey = '';

  for (let index = 0; index < rules.length; index++) {
    errorKey = validateRunner(rules[index], value);
    if (errorKey) {
      break;
    }
  }

  return errorKey;
};
