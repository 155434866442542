// import PropTypes from 'prop-types';

export default function OpenEyeSvg({ width = '22', height = '22' }: IOpenEyeSvg) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipRule="evenodd"
        stroke="#A1AFCE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M15.162 12.053a3.162 3.162 0 1 1-6.323-.001 3.162 3.162 0 0 1 6.323.001Z"
        />
        <path
          d="M11.998 19.355c3.808 0 7.291-2.738 9.252-7.302-1.961-4.564-5.444-7.302-9.252-7.302h.004c-3.808 0-7.291 2.738-9.252 7.302 1.961 4.564 5.444 7.302 9.252 7.302h-.004Z"
        />
      </g>
    </svg>
  );
}

interface IOpenEyeSvg {
  width?: string;
  height?: string;
}
