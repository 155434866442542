const baseStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.login': '/login',
  'routes.register': '/register',
  'routes.profile': '/profile',
  'routes.reports': '/reports',
  'routes.reports/:id': '/reports/:id',

  'routes.reports.advertisers': '/reports/advertisers',
  'routes.reports.advertisers/:id': '/reports/advertisers/:id',

  'routes.reports.webmasters': '/reports/webmasters',
  'routes.reports.webmasters/:id': '/reports/webmasters/:id',

  /** Page content */
  'home.title': 'Home',
  'home.content': 'Thank you for visiting this multi-language routing example.',
  'login.email': 'Email',
  'login.password': 'Password',

  /** Links */
  'links.medium': 'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',


  'modules.header.btn.login': 'Login',
  'modules.header.btn.register': 'Register',
  'modules.header.btn.logout': 'Log Out',
  'modules.header.btn.reports': 'Reports',
  'modules.header.btn.reports.advertisers': 'Advertisers',
  'modules.header.btn.reports.webmasters': 'Webmasters',
  
  'modules.login.title': 'Welcome Back to Tick-Tack',
  'modules.login.subtitle': 'Sign in to continue to your account.',
  'modules.login.btn.fb': 'Continue with Facebook',
  'modules.login.btn.google': 'Continue with Google',
  'modules.login.field.email.placeholder': 'Email',
  'modules.login.field.password.placeholder': 'Password',
  'modules.login.field.btn.remember-me': 'Keep me signed in until I sign out',
  'modules.login.field.btn.go_to_register_page': 'Sign Up.',
  'modules.login.btn.login': 'Sign In',
  'modules.login.btn.forgot_password': 'Forgot password?',

  
  'components.table.column.actions.edit': 'Edit',


  'modules.registration.title': 'Create a Account',
  'modules.registration.subtitle': 'Sign up to continue to your account.',
  'modules.registration.btn.fb': 'Sign up with Facebook',
  'modules.registration.btn.google': 'Sign up with Google',
  'modules.registration.field.firstName.placeholder': 'First Name',
  'modules.registration.field.lastName.placeholder': 'Last Name',
  'modules.registration.field.email.placeholder': 'Email',
  'modules.registration.field.password.placeholder': 'Password',
  'modules.registration.field.confirmationPassword.placeholder': 'Confirmation your password',
  'modules.registration.btn.singUp': 'Sign up',
  'modules.registration.text.member': 'Already a member?',

  'services.form-validation.error.invalid': 'Field is invalid',
  'services.form-validation.error.required': 'Field is required',
  'services.form-validation.error.email.invalid': 'Email is invalid',
};

export type LanguageStrings = typeof baseStrings;
export const en: Record<string, any> = baseStrings;
