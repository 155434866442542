import EyeSvg from './eye';
import OpenEyeSvg from './openEye';
import FacebookSvg from './facebook';
import GoogleSvg from './google';
import ArrowLeft from './arrowLeft';
import ArrowRight from './arrowRight';
import ArrowNextRight from './arrowNextRight';
import ArrowNextLeft from './arrowNextLeft';

export {
  EyeSvg,
  OpenEyeSvg,
  FacebookSvg,
  GoogleSvg,
  ArrowLeft,
  ArrowRight,
  ArrowNextRight,
  ArrowNextLeft
};
