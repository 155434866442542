import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { AppRoute } from 'const';
import { TEXTS } from './header.constants';
import { localizeRoutePath } from 'modules/i18n/';
import { useTranslate } from 'hooks/useTranslate';

import { isAuthenticated } from 'helper/auth';
import Button from 'components/button';

import styles from './header.module.scss';

import Dropdown from 'components/dropdown';

// import Navigation from 'modules/navigation';


export default function Header({ logout, className = '' }: IHeader) {
  const { formatMessage, locale } = useIntl();
  const isAuthUser = isAuthenticated();
  const [_intlFormatMessage] = useTranslate();

  const renderWithNoSession = () => (
    <>
      <div className={styles['header__actions-item']}>
        <Link to={localizeRoutePath(locale, formatMessage, AppRoute.Login)}>
          <Button
            className="std-btn--bordered"
            id="sign-in-button"
            text={formatMessage(TEXTS.login)}
          />
        </Link>
      </div>
      <div className={styles['header__actions-item']}>
        <Link to={localizeRoutePath(locale, formatMessage, AppRoute.Registration)}>
          <Button
            id="sign-in-button"
            text={formatMessage(TEXTS.register)}
          />
        </Link>
      </div>
    </>
  );

  const renderWithSession = () => (
    <>
      <div className={styles['header__actions-item']}>
        <Dropdown options={[
          <Link to={localizeRoutePath(locale, formatMessage, AppRoute.AdvertiserReport)}>
            {_intlFormatMessage('modules.header.btn.reports.advertisers')}
          </Link>,

          <Link to={localizeRoutePath(locale, formatMessage, AppRoute.WebmasterReport)}>
            {_intlFormatMessage('modules.header.btn.reports.webmasters')}
          </Link>,

        ]}>
          <Button
            id="modules.header.btn.reports"
            text={_intlFormatMessage('modules.header.btn.reports')}
          />
        </Dropdown>

        {/* <Link to={localizeRoutePath(locale, formatMessage, AppRoute.Reports)}>
          <Button
            id="modules.header.btn.reports"
            text={_intlFormatMessage('modules.header.btn.reports')}
          />
        </Link> */}
      </div>

      <div className={styles['header__actions-item']}>
        <Button
          id="sign-out-button"
          onClick={logout}
          text={formatMessage(TEXTS.logout)}
        />
      </div>
    </>

  );

  return (
    <div className={`${styles.header} ${styles[className] || ''}`}>
      <div className={styles.header__content}>
        <div className={styles.header__logo}>
          Logo
        </div>
        <div className={styles.header__actions}>
          {isAuthUser
            ? renderWithSession()
            : renderWithNoSession()
          }
        </div>
      </div>
    </div>
  );
}

interface IHeader {
  className?: string;
  logout: () => void;
}
