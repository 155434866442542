import BaseLayout from 'components/base';
import Reports from 'modules/reports';


export function AdvertiserReport() {
    return (
        <BaseLayout>
            <Reports.Advertiser />
        </BaseLayout>
    );
}


export function AdvertiserEntryDetails() {
    return (
        <BaseLayout>
            <Reports.AdvertiserDetails />
        </BaseLayout>
    );
}

export function WebmasterReport() {
    return (
        <BaseLayout>
            <Reports.Webmaster />
        </BaseLayout>
    );
}


// export function AdvertiserEntryDetails() {
//     return (
//         <BaseLayout>
//             <Reports.AdvertiserDetails />
//         </BaseLayout>
//     );
// }
