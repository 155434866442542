import Pagination from './pagination.component';
import styles from './index.module.scss';
import { ArrowLeft, ArrowRight, ArrowNextLeft, ArrowNextRight } from 'components/svg';


const PaginationView = ({
  currentPage,
  limit,
  totalPages,
  onPageChange
}: Props) => {
  return (
    <Pagination
      totalPages={totalPages}
      limit={limit}
      currentPage={currentPage}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        totalPages,
        getPageItemProps
      }) => (
        <>
          <ul className={`${styles['pagination-list']}`}>
            {
              hasPreviousPage
                ? <>
                  <li
                    {...getPageItemProps({ pageValue: 1, onPageChange })}
                    className={`${styles['pagination-list__item']} iconEV-arrow-first`}
                  >
                    <ArrowNextLeft />
                  </li>
                  <li
                    {...getPageItemProps({ pageValue: previousPage, onPageChange })}
                    className={`${styles['pagination-list__item']} iconEV-arrow-prev`}
                  >
                    <ArrowLeft />
                  </li>

                </>
                : null
            }

            {pages.map((page, index) => {
              return (
                <li
                  {...getPageItemProps({
                    pageValue: page,
                    key: page,
                    onPageChange
                  })}
                  className={`${styles['pagination-list__item']} ${currentPage === page ? styles['active'] : ''}`}
                  key={index}
                >
                  {page}
                </li>
              );
            })}

            {
              hasNextPage
                ? (
                  <>
                    <li
                      {...getPageItemProps({ pageValue: nextPage, onPageChange })}
                      className={`${styles['pagination-list__item']} iconEV-arrow-next`}
                    >
                      <ArrowRight />
                    </li>

                    <li
                      {...getPageItemProps({ pageValue: totalPages, onPageChange })}
                      className={`${styles['pagination-list__item']} iconEV-arrow-last`}
                    >
                      <ArrowNextRight />
                    </li>

                  </>
                )
                : null
            }
          </ul>
        </>
      )}
    </Pagination>
  );
};

type Props = {
  currentPage: number;
  totalPages: number;
  limit: number;
  onPageChange: (value: number) => void;
};

export default PaginationView;
