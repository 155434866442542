import styles from './index.module.scss';

export default function Footer({ className = '' }: IFooter) {
  return (
    <div className={`${styles.footer} ${styles[className] || ''}`}>
      <div className={styles.footer__content}>
        <div className={styles.footer__logo}>
          Logo
        </div>
        <div className={styles.footer__info}>
          <div className={styles['footer-nav']}>
            <div className={styles['footer-nav__title']}>
              Nav Title 1
            </div>
            <div className={styles['footer-nav__item']}>
              nav item menu 1
            </div>
            <div className={styles['footer-nav__item']}>
              nav item menu 2
            </div>
            <div className={styles['footer-nav__item']}>
              nav item menu 3
            </div>
          </div>
          <div className={styles['footer-nav']}>
            <div className={styles['footer-nav__title']}>
              Nav Title 2
            </div>
            <div className={styles['footer-nav__item']}>
              nav item menu 1
            </div>
            <div className={styles['footer-nav__item']}>
              nav item menu 2
            </div>
            <div className={styles['footer-nav__item']}>
              nav item menu 3
            </div>
          </div>
          <div className={styles['footer-nav']}>
            <div className={styles['footer-nav__title']}>
              Contact us
            </div>
            <div className={styles['footer-nav__item']}>
              +123 45 67 890
            </div>
            <div className={styles['footer-nav__item']}>
              Email
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IFooter {
  className?: string;
}
