export enum AppRoute {
  Home = 'routes.home',
  Login = 'routes.login',
  Registration = 'routes.register',
  Profile = 'routes.profile',
  Reports = 'routes.reports',
  AdvertiserReport = 'routes.reports.advertisers',
  WebmasterReport = 'routes.reports.webmasters',

}

export const AppRouteTitles = new Map([
  [AppRoute.Home, 'home.title'],
]);
