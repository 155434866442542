import styles from './index.module.scss';

export default function Checkbox({ 
  className = '', 
  id,
  onChange,
  checked,
  text,
  component
} : ICheckbox) {
  return (
    <div className={`${styles['checkboxes-item']} ${styles[className] || ''}`}>
      <label
        className={styles['checkboxes-item__label']}
        htmlFor={id}
      >
        <input
          id={id}
          className={styles['checkboxes-item__input']}
          type="checkbox"
          onChange={onChange}
          checked={checked}
        />
        { component || (
        <>
          <div className={styles['checkboxes-item__svg']}>
            <svg
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="20" height="20" rx="2" ry="2" />
              <path
                d="M2 6.77778L6.22222 12L17.6667 0"
                transform="scale(1.1) translate(3.81068 5.76627)"
              />
            </svg>
          </div>
          <span className={styles['checkboxes-item__text']}>
            {text}
          </span>
        </>
        )}
      </label>
    </div>
  )
}

interface ICheckbox {
  className?: string;
  id?: string;
  onChange?: any;
  checked?: any;
  text?: string;
  component?: Node,
}
