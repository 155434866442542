// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_pagination-list__BPagg {
  position: relative;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0; }
  .pagination_pagination-list__item__q\\+RiF {
    font-size: 15px;
    margin: 0 2px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    @media screen and (max-width: 480px) {
      .pagination_pagination-list__item__q\\+RiF {
        font-size: 13px; } }
    .pagination_pagination-list__item__q\\+RiF.pagination_active__g\\+TXF {
      background-color: #F98653; }
    .pagination_pagination-list__item__q\\+RiF:hover {
      background-color: #ffd2bb; }
`, "",{"version":3,"sources":["webpack://./src/components/pagination/index.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACE,kBAAkB;EAElB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAwB,EAAA;EAExB;IACE,eAAe;IACf,aAAa;IACb,iBAAiB;IAEjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IAEnB,eAAe,EAAA;IACf;MAVF;QAWI,eAAe,EAAA,EAQlB;IAnBA;MAcG,yBCvBmB,EAAA;IDStB;MAiBG,yBCzBwB,EAAA","sourcesContent":["@import 'styles/colors.scss';\n@import 'styles/indents.scss';\n\n.pagination-list {\n  position: relative;\n  display: -webkit-flex;\n  display: -moz-flex;\n  display: -ms-flex;\n  display: -o-flex;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: $indent-medium 0;\n\n  &__item {\n    font-size: 15px;\n    margin: 0 2px;\n    padding: 5px 10px;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    cursor: pointer;\n    @media screen and (max-width: 480px) {\n      font-size: 13px;\n    }\n    &.active {\n      background-color: $color-secondary;\n    }\n    &:hover {\n      background-color: $color-secondary-lite;\n    }\n  }\n}","$dark: #222222;\n$white: #FFF;\n\n$color-primary: #F44937;\n$color-primary-lite: #ff6868;\n$color-secondary: #F98653;\n$color-secondary-lite: #ffd2bb;\n$additional-color: #026398;\n\n$grey-100: #F5F7FA;\n$grey-200: #F1F2F4;\n$grey-300: #E8EAED;\n$grey-400: #DDDDDD;\n$grey-500: #C1C2C7;\n$grey-600: #BABABA;\n$grey-650: #ACACAC;\n$grey-700: #777777;\n$grey-800: #3C4050;\n$grey-900: #2C3242;\n\n$primary: #00D89D;\n$blue: #007AFF;\n$danger: #E30000;\n\n$green-primary: #008C66;\n$green-primary-hover: #00996F;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination-list": `pagination_pagination-list__BPagg`,
	"pagination-list__item": `pagination_pagination-list__item__q+RiF`,
	"active": `pagination_active__g+TXF`
};
export default ___CSS_LOADER_EXPORT___;
