import React from 'react';
import styles from './index.module.scss';


export default function Row({ children, className = '' }: IRow) {
  return (
    <div className={`${styles.row} ${styles[className] || ''}`}>
      {children}
    </div>
  );
}

interface IRow {
  className?: string;
  children: React.ReactNode;
}
