export default function FacebookSvg({ width = '18', height = '18' }: IFacebookSvg) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 18 18"
    >
      <path
        id="facebook-fn-2"
        d="M17.006,0H.994A.994.994,0,0,0,0,.994V17.007A.993.993,0,0,0,.994,18H9.615V11.03H7.269V8.313H9.615v-2a3.273,3.273,0,0,1,3.494-3.591,19.242,19.242,0,0,1,2.1.107v2.43H13.767c-1.128,0-1.346.536-1.346,1.322V8.314h2.69l-.35,2.717h-2.34V18h4.587A.993.993,0,0,0,18,17.006V.994A.994.994,0,0,0,17.006,0Z"
        fill="#222"
      />
    </svg>
  );
}

interface IFacebookSvg {
  width?: string;
  height?: string;
}

