import Dispatch from 'types/dispatch';
import API from 'API';

import { changeLanguage } from 'modules/i18n/i18n.actions';
import { setUserData } from 'modules/user/user.actions';
import { JwtHelper } from 'helper/auth';

import { LOG_OUT_ACTION } from './login.constants';
import { AUTH_TOKENS } from 'const';

import { LoginPayload } from './login.types';

export const changeLanguageLogin = (value: string) => (dispatch: Dispatch) => {
  dispatch(changeLanguage(value));
};

export const logout = () => async (dispatch: Dispatch) => {
  await API.get('api/v1/auth/logOut').catch((err) => console.error(err));

  JwtHelper.delete();
  dispatch({ type: LOG_OUT_ACTION });
};

export const login = (payload: LoginPayload) => async (dispatch: Dispatch) => {
  try {
    const { data } = await API.post('api/v1/auth/login', payload);

    const { data: {
      accessToken, refreshToken
    } } = data.results;

    JwtHelper.set(AUTH_TOKENS.ACCESS, accessToken);
    JwtHelper.set(AUTH_TOKENS.REFRESH, refreshToken);
  } catch (error) {
    // TODO: Execute react notification error
    // https://www.npmjs.com/package/react-notifications-component
    JwtHelper.delete();
  }

  try {
    const { data: response } = await API.get('api/v1/user/me');
    console.log(`response`, response);
    return dispatch(setUserData(response.user));
  } catch (error) {
    // TODO: Execute react notification error
    // https://www.npmjs.com/package/react-notifications-component
    JwtHelper.delete();
  }
};

export type LoginActions =
  ReturnType<typeof login> |
  ReturnType<typeof changeLanguageLogin> |
  ReturnType<typeof logout>;
