import axios from 'axios';
import { get, set } from 'lodash';
import { AUTH_TOKENS } from 'const';
import { JwtHelper } from '../helper/auth';

console.log('process.env', process.env);
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000',
  // baseURL:  'http://localhost:3000',
  // headers: {
  //   Authorization: token.get() ? `Bearer ${token.get()}` : '',
  //   'x-header-id': 'Some_id',
  // },
});

instance.interceptors.request.use(
  (config) => {
    // perform a task before the request is sent
    const token = JwtHelper.get(AUTH_TOKENS.ACCESS);
    const headers = get(config, 'headers', {});
    if (token) {
      set(config, 'headers', { ...headers, Authorization: `Bearer ${token}` });
    }

    return config;
  },
  (error) => {
    // handle the error
    return Promise.reject(error);
  },
);

export default instance;
