import React from 'react';
// import { injectIntl } from 'react-intl';

export default class Profile extends React.Component {
    render() {
        return(
            <div> I Am profile page</div>
        )
    }
}

// export default injectIntl(Profile);