import { connect } from 'react-redux';
import { logout } from 'modules/login/login.actions';
import Header from './header.component';
import { RootState } from 'store/reducers';

const mapStateToProps = (state: RootState) => {
  return ({
    isAuthUser: state.user.isAuthUser,
    user: state.user.profile,
  });
};

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
