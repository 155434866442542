// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.base_out__s51r9 {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .base_out__main__PNNO3 {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 16px;
    flex: 1 0 auto;
    width: 100%;
    padding-top: 40px; }
`, "",{"version":3,"sources":["webpack://./src/components/base/index.module.scss","webpack://./src/styles/mixins.scss","webpack://./src/styles/indents.scss"],"names":[],"mappings":"AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAA;EACjB;ICMA,iBAAiB;IACjB,cAAc;IACd,eCbmB;IFOjB,cAAc;IACd,WAAW;IACX,iBENmB,EAAA","sourcesContent":["@import 'styles/indents.scss';\n@import 'styles/mixins.scss';\n\n.out {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  &__main {\n    @include main-wrap;\n    flex: 1 0 auto;\n    width: 100%;\n    padding-top: $indent-extra-big;\n  }\n}\n","@mixin flex-center {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin content-wrap {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 $indent-primary;\n}\n\n@mixin main-wrap {\n  max-width: 1600px;\n  margin: 0 auto;\n  padding: 0 $indent-primary;\n}\n\n@mixin box-shadow {\n  box-shadow: 0px 2px 5px rgba(0,0,0,.16);\n}\n\n@mixin css-lock($min-size: 1, $max-size: 1.4, $min-width: 20, $max-width: 100, $property: font-size, $unit: px) {\n  #{$property}: #{$max-size}#{$unit};\n  #{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})));\n  @media (max-width: #{$min-width}#{$unit}) {\n    #{$property}: #{$min-size}#{$unit};\n  }\n  @media (min-width: #{$max-width}#{$unit}) {\n    #{$property}: #{$max-size}#{$unit};\n  }\n}\n","$indent-small: 8px;\n$indent-medium: 12px;\n$indent-primary: 16px;\n$indent-regular: 24px;\n$indent-big: 32px;\n$indent-extra-big: 40px;\n$indent-huge: 60px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"out": `base_out__s51r9`,
	"out__main": `base_out__main__PNNO3`
};
export default ___CSS_LOADER_EXPORT___;
