


export default function ArrowLeft({ width = '22', height = '22' }: IArrowLeft) {
    return (
        <svg
            height={`${height}px`}
            viewBox="0 0 48 48" width={`${width}px`}
            xmlns="http://www.w3.org/2000/svg">
            <path d="M30.83 32.67l-9.17-9.17 9.17-9.17-2.83-2.83-12 12 12 12z" fill="currentColor" />
            <path d="M0-.5h48v48h-48z" fill="none" />
        </svg>
    );
}

interface IArrowLeft {
    width?: string;
    height?: string;
}
