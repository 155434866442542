import React from 'react';
import styles from './index.module.scss';

export default function Col({ children, className = '', col = '12' }: ICol) {
  const column = `col-${col}`;
  return (
    <div className={`${styles[column]} ${styles[className] || ''}`}>
      {children}
    </div>
  );
}

interface ICol {
  className?: string;
  children: React.ReactNode;
  col?: string;
}
