// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__Yb\\+Sz {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
  padding: 12px 24px; }
  .header_header__content__HE3o- {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto; }
  .header_header__actions__Nq1ZF {
    display: flex; }
  .header_header__actions-item__IKhKi {
    margin-left: 12px; }
`, "",{"version":3,"sources":["webpack://./src/modules/header/header.module.scss","webpack://./src/styles/mixins.scss","webpack://./src/styles/indents.scss"],"names":[],"mappings":"AAIA;ECeE,2CAAuC;EDbvC,kBEHmB,EAAA;EFKnB;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,cAAc,EAAA;EAEhB;IACE,aAAa,EAAA;EAEf;IACE,iBElBgB,EAAA","sourcesContent":["@import 'styles/indents.scss';\n@import 'styles/colors.scss';\n@import 'styles/mixins.scss';\n\n.header {\n  @include box-shadow;\n  padding: $indent-medium $indent-regular;\n\n  &__content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    max-width: 1920px;\n    margin: 0 auto;\n  }\n  &__actions {\n    display: flex;\n  }\n  &__actions-item {\n    margin-left: $indent-medium;\n  }\n}\n","@mixin flex-center {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin content-wrap {\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 0 $indent-primary;\n}\n\n@mixin main-wrap {\n  max-width: 1600px;\n  margin: 0 auto;\n  padding: 0 $indent-primary;\n}\n\n@mixin box-shadow {\n  box-shadow: 0px 2px 5px rgba(0,0,0,.16);\n}\n\n@mixin css-lock($min-size: 1, $max-size: 1.4, $min-width: 20, $max-width: 100, $property: font-size, $unit: px) {\n  #{$property}: #{$max-size}#{$unit};\n  #{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})));\n  @media (max-width: #{$min-width}#{$unit}) {\n    #{$property}: #{$min-size}#{$unit};\n  }\n  @media (min-width: #{$max-width}#{$unit}) {\n    #{$property}: #{$max-size}#{$unit};\n  }\n}\n","$indent-small: 8px;\n$indent-medium: 12px;\n$indent-primary: 16px;\n$indent-regular: 24px;\n$indent-big: 32px;\n$indent-extra-big: 40px;\n$indent-huge: 60px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__Yb+Sz`,
	"header__content": `header_header__content__HE3o-`,
	"header__actions": `header_header__actions__Nq1ZF`,
	"header__actions-item": `header_header__actions-item__IKhKi`
};
export default ___CSS_LOADER_EXPORT___;
