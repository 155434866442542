/* eslint-disable import/no-anonymous-default-export */
import Advertiser from './advertiser.component';
import Webmaster from './webmaster.component';
import AdvertiserDetails from './advertiser-details';

export default {
    Advertiser,
    AdvertiserDetails,

    Webmaster,
};