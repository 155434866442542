import { useState, useEffect, useCallback, ReactNode } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslate } from 'hooks/useTranslate';

import API from 'API';
import styles from './index.module.scss';
import Pagination from 'components/pagination';

import Button from 'components/button';

export default function Table({
  url,
  structure,
  limit = 5,
  withActions = true
}: ITable) {
  const [_intlFormatMessage] = useTranslate();
  const match = useRouteMatch();

  const [data, setData] = useState<IEntry<{ id: number, [key: string]: any }>[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);

  const fetchDataCb = useCallback(async (page: number) => {
    try {
      const { data } = await API.get(`${url}?page=${page || 1}&limit=${limit}`);
      setData(data.results.entries);
      setTotalPages(data.results.totalPages);
      setIsLoading(false);
    } catch (error) {
      console.log(`error`, error);
      // TODO: Execute react notification error
      // https://www.npmjs.com/package/react-notifications-component
    }
  }, [limit, url]);

  useEffect(() => {
    fetchDataCb(1);
  }, [fetchDataCb]);

  useEffect(() => {
    if (page === 0) return;

    fetchDataCb(page);
  }, [page, fetchDataCb]);

  const onPageChangeCb = useCallback((value: number) => setPage(value), []);

  if (isLoading) {
    return <div className={styles['std-table']}>
      <div className="std-table__loading">Fetching data...</div>
    </div>;
  }

  let columns = withActions ? [...structure, 'Actions'] : structure;

  return <div className={styles['std-table']}>
    <table>
      <thead>
        <tr>
          {columns.map((key, index) => key === 'isActive' ? <th key={index}>Active</th> : <th key={index}>{key}</th>)}
        </tr>
      </thead>

      <tbody>
        {data.map((entry, index) => (
          <tr key={index}>
            {columns.map((column, indexKey) =>
              renderView(column, { id: entry.id, indexKey, entry, t: _intlFormatMessage, match })
            )}
          </tr>
        ))}
      </tbody>
    </table>

    <Pagination
      currentPage={page || 1}
      totalPages={totalPages}
      limit={limit}
      onPageChange={onPageChangeCb}
    />
  </div>;
}


const renderView = (key: string, options: Record<string, any>) => {
  const views: Record<string, ReactNode> = {
    isActive: <td key={options.indexKey} data-cell="Active">{options.entry[key] ? 'Yes' : 'No'}</td>,
    Actions: <td key={options.indexKey} data-cell="Actions">
      <Link to={`${options.match.url}/${options.id}`}>
        <Button text={options.t('components.table.column.actions.edit')} />
      </Link>
    </td>,
  };

  return views[key] || <td key={options.indexKey} data-cell={key}>{options.entry[key]}</td>;
};

type IEntry<T> = Record<string, T>;

export interface ITable {
  url: string;
  limit?: number;
  structure: Array<string>;
  withActions?: boolean;
}
