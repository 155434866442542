import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Redirect, RouteComponentProps, RouteProps } from 'react-router-dom';
import { AppRoute, AppLanguage } from 'const';
import { store } from 'store';
import { LocalizedRouter, LocalizedSwitch, appStrings } from 'modules/i18n/';

import Home from 'pages/home';
import Login from 'pages/login';
import Registration from 'pages/registration';
import Profile from 'pages/profile';
import { AdvertiserReport, AdvertiserEntryDetails, WebmasterReport } from 'pages/reports';

import NotFoundPage from 'pages/notFound';


interface ProtectedRoute extends RouteProps {
  type?: string;
  path: string;
  isAuthUser?: boolean;
  component: React.ComponentType<RouteComponentProps>
}

const AuthRoute: React.FC<ProtectedRoute> = ({ isAuthUser, component: Component, type, ...rest }) => {
  if (!isAuthUser && type === 'guest') {
    return <Route {...rest} render={routeProps => <Component {...routeProps} />} />;
  }

  return isAuthUser && type !== 'guest'
    ? <Route {...rest} render={routeProps => <Component {...routeProps} />} />
    : <Redirect to={{
      pathname: '/',
      // search: `?callback_url=${encodeURIComponent(window.location.href)}`
    }} />;
};

const Root: React.FC = () => (
  <Provider store={store}>
    <LocalizedRouter
      RouterComponent={BrowserRouter}
      languages={AppLanguage}
      appStrings={appStrings}
    >
      <LocalizedSwitch>
        <Route exact path={AppRoute.Home}>
          <Home />
        </Route>

        <AuthRoute path={AppRoute.Login} component={Login} type="guest" />
        <AuthRoute path={AppRoute.Registration} component={Registration} type="guest" />
        <AuthRoute path={AppRoute.Profile} component={Profile} />

        <AuthRoute path={'routes.reports.advertisers/:id'} component={AdvertiserEntryDetails} />
        <AuthRoute path={AppRoute.AdvertiserReport} component={AdvertiserReport} />


        {/* <AuthRoute path={'routes.reports.webmaster/:id'} component={AdvertiserEntryDetails} /> */}
        <AuthRoute path={AppRoute.WebmasterReport} component={WebmasterReport} />
        

        <Route component={NotFoundPage} />
      </LocalizedSwitch>
    </LocalizedRouter>
  </Provider>
);

export default Root;
