import React from 'react';
import styles from './index.module.scss';

export default function Button({
  className = '',
  disabled = false,
  submit,
  id,
  onClick,
  svg,
  text,
}: IButton) {
  return (
    <button
      className={`${styles['std-btn']} ${styles[className] || ''}`}
      id={id}
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled}
    >
      {svg
        &&
        <div className={`${styles['std-btn__svg']}`}>
          {svg}
        </div>
      }
      {text}
    </button>
  )
}

interface IButton {
  className?: string;
  svg?: React.ReactNode;
  submit?: boolean;
  disabled?: boolean;
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text?: React.ReactNode;
}
