import Registration from 'modules/registration';
import BaseLayout from 'components/base';

export default function RegistrationPage() {
    return (
        <BaseLayout>
            <Registration />
        </BaseLayout>
    );
}
