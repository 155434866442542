// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown_std-dropdown__0MdXm {
  padding: 0;
  position: relative;
  cursor: pointer; }
  .dropdown_std-dropdown__0MdXm:hover .dropdown_std-dropdown__pannel__d03Yz {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 180; }
  .dropdown_std-dropdown__pannel__d03Yz {
    min-width: 200px;
    width: 100%;
    padding: 16px 0;
    position: absolute;
    top: 100%;
    background-color: #FFF;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: all 0.25s ease-in-out;
    box-shadow: 0px 4px 20px rgba(71, 82, 109, 0.2);
    z-index: -1; }
  .dropdown_std-dropdown__option__cB9\\+- {
    padding: 8px 16px;
    transition: background-color 0.25s ease-in-out;
    cursor: pointer; }
    .dropdown_std-dropdown__option__cB9\\+-:hover {
      background-color: #C1C1C1; }
`, "",{"version":3,"sources":["webpack://./src/components/dropdown/index.module.scss"],"names":[],"mappings":"AAGA;EACE,UAAU;EACV,kBAAkB;EAClB,eAAe,EAAA;EAHjB;IAQQ,UAAU;IACV,mBAAmB;IACnB,wBAAwB;IACxB,YAAY,EAAA;EAKlB;IACE,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,SAAS;IAET,sBAAsB;IACtB,kBAAkB;IAElB,UAAU;IACV,kBAAkB;IAElB,2BAA2B;IAC3B,iCAAiC;IACjC,+CAA+C;IAC/C,WAAW,EAAA;EAIb;IACE,iBAAiB;IACjB,8CAA8C;IAC9C,eAAe,EAAA;IAHhB;MAOG,yBAAyB,EAAA","sourcesContent":["@import 'styles/indents.scss';\n@import 'styles/colors.scss';\n\n.std-dropdown {\n  padding: 0;\n  position: relative;\n  cursor: pointer;\n\n  &:hover {\n    .std-dropdown {\n      &__pannel {\n        opacity: 1;\n        visibility: visible;\n        transform: translateY(0);\n        z-index: 180;\n      }\n    }\n  }\n\n  &__pannel {\n    min-width: 200px;\n    width: 100%;\n    padding: 16px 0;\n    position: absolute;\n    top: 100%;\n\n    background-color: #FFF;\n    border-radius: 4px;\n\n    opacity: 0;\n    visibility: hidden;\n\n    transform: translateY(20px);\n    transition: all 0.25s ease-in-out;\n    box-shadow: 0px 4px 20px rgba(71, 82, 109, 0.2);;\n    z-index: -1;\n  }\n\n\n  &__option {\n    padding: 8px 16px;\n    transition: background-color 0.25s ease-in-out;\n    cursor: pointer;\n\n\n    &:hover {\n      background-color: #C1C1C1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"std-dropdown": `dropdown_std-dropdown__0MdXm`,
	"std-dropdown__pannel": `dropdown_std-dropdown__pannel__d03Yz`,
	"std-dropdown__option": `dropdown_std-dropdown__option__cB9+-`
};
export default ___CSS_LOADER_EXPORT___;
