export const TEXTS = {
  title: { id: 'modules.registration.title' },
  subtitle: { id: 'modules.registration.subtitle' },
  loginWithFB: { id: 'modules.registration.btn.fb' },
  loginWithGoogle: { id: 'modules.registration.btn.google' },
  placeholders: {
    firstName: { id: 'modules.registration.field.firstName.placeholder' },
    lastName: { id: 'modules.registration.field.lastName.placeholder' },
    email: { id: 'modules.registration.field.email.placeholder' },
    password: { id: 'modules.registration.field.password.placeholder' },
    confirmPassword: { id: 'modules.registration.field.confirmationPassword.placeholder' },
  },
  singUp: {id: 'modules.registration.btn.singUp'},
  text: {
    member: {id: 'modules.registration.text.member'}
  }
};
