export type Pagination = {
  totalPages: number;
  pages: number;
  currentPage: number;
  firstPage: number;
  lastPage: number;
  previousPage: number;
  nextPage: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  totalResults: number;
  results: number;
  firstResult: number;
  lastResult: number;
};

export type PagingInfo = {
  totalPages: number;
  currentPage: number;
  limit: number;
};

export function getRange(start: number, end: number): number[] {
  return [...Array(end - start + 1)].map((_, i) => start + i);
}

export function getPageInfo({ limit, totalPages, currentPage }: PagingInfo) {
  let _currentPage = currentPage;

  if (currentPage < 1) {
    _currentPage = 1;
  }

  if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let firstPage = Math.max(1, currentPage - Math.floor(totalPages / 2));
  let lastPage = Math.min(
    totalPages,
    _currentPage + Math.floor(totalPages / 2)
  );

  if (lastPage - firstPage + 1 < totalPages) {
    if (_currentPage < totalPages / 2) {
      lastPage = Math.min(
        totalPages,
        lastPage + (totalPages - (lastPage - firstPage))
      );
    } else {
      firstPage = Math.max(1, firstPage - (totalPages - (lastPage - firstPage)));
    }
  }

  if (lastPage - firstPage + 1 > totalPages) {
    if (currentPage > totalPages / 2) {
      firstPage = firstPage + 1;
    } else {
      lastPage = lastPage - 1;
    }
  }

  const pages = Math.min(lastPage - firstPage + 1, totalPages);
  const previousPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = currentPage < totalPages;
  const firstResult = limit * (currentPage - 1);
  const lastResult = limit * currentPage - 1;


  return {
    totalPages,
    pages,
    currentPage,
    firstPage,
    lastPage,
    previousPage,
    nextPage,
    hasPreviousPage,
    hasNextPage,
    firstResult,
    lastResult
  };
}
