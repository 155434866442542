import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { AppRoute } from 'const';
import { TEXTS } from './login.constants';

import { Button, Checkbox, Input } from 'components';
import { EyeSvg, OpenEyeSvg, FacebookSvg, GoogleSvg } from 'components/svg';

import { REQUIRED, IS_EMAIL, validate } from 'modules/form-validation';
import { localizeRoutePath } from 'modules/i18n/';

import { ILoginState, ILoginProps, LOGIN_FORM_KEYS } from './login.types';

import styles from './index.module.scss';


export class Login extends React.Component<ILoginProps, ILoginState> {
  public state = {
    [LOGIN_FORM_KEYS.EMAIL]: {
      value: '',
      error: '',
      validationList: [REQUIRED, IS_EMAIL]
    },
    [LOGIN_FORM_KEYS.PASSWORD]: {
      value: '',
      showPassword: false,
      error: '',
      validationList: [REQUIRED]
    },
  };

  handleLogin = () => {
    const { email, password } = this.state;

    const emailErrorKey = validate(email.value, email.validationList);
    const passwordErrorKey = validate(password.value, password.validationList);

    if (emailErrorKey || passwordErrorKey) {
      return this.setState(state => ({
        email: {
          ...state.email,
          error: emailErrorKey
        },
        password: {
          ...state.password,
          error: passwordErrorKey
        }
      }));
    }

    return this.props.login({ email: email.value, password: password.value });
  };

  onShowPasswordClick = () => {
    return this.setState(state => ({
      ...state,
      password: {
        ...state.password,
        showPassword: !state.password.showPassword
      }
    }));
  };

  onInputChange = ({ target: { value, id: key } }: React.ChangeEvent<HTMLInputElement>) => (
    this.setState(state => ({
      ...state,
      [key]: {
        ...(state[key as LOGIN_FORM_KEYS]),
        error: '',
        value
      }
    }))
  );

  render() {
    const { email, password } = this.state;
    const { intl } = this.props;

    return (
      <div className={styles.entrance}>
        <div className={styles.entrance__info}>
          <div className={styles['entrance__info-body']}>
            {/* TODO: add logo */}
            <div className={styles.entrance__logo}>logo</div>
            <div className={styles.entrance__title}>
              {intl.formatMessage(TEXTS.title)}
            </div>
            <div className={styles['entrance__decorate-line']} />
            <div className={styles.entrance__subtitle}>
              {intl.formatMessage(TEXTS.subtitle)}
            </div>
          </div>
        </div>
        <div className={styles.entrance__descr}>
          <div className={styles.entrance__row}>
            <div className={`${styles.entrance__col} ${styles['mb-medium']}`}>
              <Button
                className='std-btn--social-bordered'
                id='sign-in-button'
                text={intl.formatMessage(TEXTS.loginWithFB)}
                svg={<FacebookSvg />}
              />
            </div>
          </div>
          <div className={styles.entrance__row}>
            <div className={`${styles.entrance__col} ${styles['mb-medium']}`}>
              <Button
                className='std-btn--social-bordered'
                id='sign-in-button'
                text={intl.formatMessage(TEXTS.loginWithGoogle)}
                svg={<GoogleSvg />}
              />
            </div>
          </div>
          <div className={styles['entrance__separate-block']}>
            <span>or</span>
          </div>
          <div className={styles.entrance__row}>
            <div className={styles.entrance__col}>
              <Input
                id={LOGIN_FORM_KEYS.EMAIL}
                className={email.error ? 'std-field__input--error' : 'std-field__input'}
                type='text'
                placeholder={intl.formatMessage(TEXTS.placeholders.email)}
                value={email.value}
                onChange={this.onInputChange}
                error={email.error !== '' ? intl.formatMessage({id: email.error}) : ''}
              />
            </div>
          </div>
          <div className={styles.entrance__row}>
            <div className={styles.entrance__col}>
              <Input
                id={LOGIN_FORM_KEYS.PASSWORD}
                className={password.error ? 'std-field__input--error' : 'std-field__input'}
                type={password.showPassword ? 'text' : 'password'}
                placeholder={intl.formatMessage(TEXTS.placeholders.password)}
                value={password.value}
                onChange={this.onInputChange}
                onClick={this.onShowPasswordClick}
                svg={ password.showPassword ? <OpenEyeSvg /> : <EyeSvg />}
                error={password.error !== '' ? intl.formatMessage({id: password.error}) : ''}
              />
            </div>
          </div>
          <div
            className={`${styles.entrance__row} ${styles['entrance__row--mb-regular']}`}
          >
            <div className={styles.entrance__col}>
              <Checkbox id='keep-signed-in' text={intl.formatMessage(TEXTS.rememberMe)}/>
            </div>
          </div>
          <div
            className={`${styles.entrance__row} ${styles['entrance__row--mb-small']}`}
          >
            <div className={styles.entrance__buttons}>
              <Button
                className='std-btn--full-width'
                submit
                id='sign-in-button'
                text={intl.formatMessage(TEXTS.login)}
                onClick={this.handleLogin}
              />
            </div>
          </div>
          <div
            className={`${styles.entrance__row} ${styles['entrance__row--mb-regular']}`}
          >
            {/* TODO: redirect to reset password page */}
            <Link
              className={styles.entrance__link}
              to="#"
              // to={localizeRoutePath(intl.locale, intl.formatMessage, AppRoute.Registration)}
            >
              {intl.formatMessage(TEXTS.goToResetPasswordPage)}
            </Link>

          </div>
          <div className={styles.entrance__bottom}>
            <div className={styles.entrance__text}>Not a member yet?</div>
            &nbsp;
            <Link
              className={styles.entrance__link}
              to={localizeRoutePath(intl.locale, intl.formatMessage, AppRoute.Registration)}
            >
              {intl.formatMessage(TEXTS.goToRegisterPage)}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Login);
