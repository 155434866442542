import { IntlFormatters } from 'react-intl';

/**
 *
 * @param path can be string, undefined or string array
 * @returns Localized string path or path array
 */
export function localizeRoutePath(
  locale: string,
  formatMessage: IntlFormatters['formatMessage'],
  path?: string | string[],
) {
  switch (typeof path) {
    case 'undefined':
      return undefined;
    case 'object':
      return path.map((key) => `/${locale}` + formatMessage({ id: key }));
    default:
      const isFallbackRoute = path === '*';
      return isFallbackRoute ? path : `/${locale}` + formatMessage({ id: path });
  }
}
