import Dispatch from 'types/dispatch';
import API from 'API';
import { JwtHelper } from 'helper/auth';

import { setUserData } from 'modules/user/user.actions';

import { AUTH_TOKENS } from 'const';
import { RegistrationPayload } from './registration.types';


export const registration = (payload: RegistrationPayload) => async (dispatch: Dispatch) => {
  try {
    const { data } = await API.post('api/v1/auth/register', {
      ...payload,
      agreeToPrivacyPolicy: true,
      agreeToTermsOfUse: true
    });

    const { user, accessToken, refreshToken } = data.results;

    JwtHelper.set(AUTH_TOKENS.ACCESS, accessToken);
    JwtHelper.set(AUTH_TOKENS.REFRESH, refreshToken);

    return dispatch(setUserData(user));
  } catch (error) {
    // TODO: Execute react notification error
    // https://www.npmjs.com/package/react-notifications-component
    JwtHelper.delete();
  }
};

export type RegistrationActions = 
  ReturnType<typeof registration>;