import React from 'react';
import { Row, Col } from 'components/grid';
import BaseLayout from 'components/base';

export default class Home extends React.Component {
  render() {
    return (
      <BaseLayout>
        <span>I am Home page title</span>
        <Row>
            <Col>i am a future content</Col>
        </Row>
      </BaseLayout>
    )
  }
}
