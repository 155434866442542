// import { useState, useEffect, useCallback, ReactNode } from 'react';
import { useParams } from 'react-router-dom';

export default function AdvertiserDetails () {
    const params = useParams<Record<string, string>>();
    // console.log(`params`, params); // {id: '1'}

    return <div className="std-advertiser-details">
        I am entry from Advertiser report with ID: {params.id}
    </div>;
}