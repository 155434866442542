import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { REQUIRED, IS_EMAIL, validate } from 'modules/form-validation';
import { localizeRoutePath } from 'modules/i18n/';

import { Button, Input } from 'components';
import { EyeSvg, OpenEyeSvg, FacebookSvg, GoogleSvg } from 'components/svg';

import { AppRoute } from 'const';
import { TEXTS } from './registration.constants';

import { IRegistrationProps, IRegistrationState, REGISTRATION_FORM_KEYS } from './registration.types';
import styles from './index.module.scss';


const initialEntryState = {
  value: '',
  error: '',
};

export class Registration extends React.Component<IRegistrationProps, IRegistrationState> {
  public state = {
    [REGISTRATION_FORM_KEYS.FIRSTNAME]: {
      ...initialEntryState,
      validationList: [REQUIRED]
    },
    [REGISTRATION_FORM_KEYS.LASTNAME]: {
      ...initialEntryState,
      validationList: [REQUIRED]
    },
    [REGISTRATION_FORM_KEYS.EMAIL]: {
      ...initialEntryState,
      validationList: [REQUIRED, IS_EMAIL]
    },
    [REGISTRATION_FORM_KEYS.PASSWORD]: {
      ...initialEntryState,
      showPassword: false,
      validationList: [REQUIRED]
    },
    [REGISTRATION_FORM_KEYS.CONFIRMATION_PASSWORD]: {
      ...initialEntryState,
      showPassword: false,
      validationList: [REQUIRED]
    }
  };

  handleRegistration = () => {
    const { firstName, lastName, email, password, confirmPassword } = this.state;
    const { registration } = this.props;

    const firstNameErrorKey = validate(firstName.value, firstName.validationList);
    const lastNameErrorKey = validate(lastName.value, lastName.validationList);
    const emailErrorKey = validate(email.value, email.validationList);
    const passwordErrorKey = validate(password.value, password.validationList);
    const confirmPasswordErrorKey = validate(confirmPassword.value, confirmPassword.validationList);

    if (
      firstNameErrorKey ||
      lastNameErrorKey ||
      emailErrorKey ||
      passwordErrorKey ||
      confirmPasswordErrorKey
    ) {
      return this.setState(state => ({
        firstName: { ...state.firstName, error: firstNameErrorKey },
        lastName: { ...state.lastName, error: lastNameErrorKey },
        email: { ...state.email, error: emailErrorKey },
        password: { ...state.password, error: passwordErrorKey },
        confirmPassword: { ...state.confirmPassword, error: confirmPasswordErrorKey }
      }));
    }

    return registration({
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      confirmPassword: confirmPassword.value
    });
  };

  onShowPasswordClick = () => {
    return this.setState(state => ({
      ...state,
      password: {
        ...state.password,
        showPassword: !state.password.showPassword
      }
    }));
  };

  onConfirmPasswordClick = () => {
    return this.setState(state => ({
      ...state,
      confirmPassword: {
        ...state.confirmPassword,
        showPassword: !state.confirmPassword.showPassword
      }
    }));
  };


  onInputChange = ({ target: { value, id: key } }: React.ChangeEvent<HTMLInputElement>) => (
    this.setState(state => ({
      ...state,
      [key]: {
        ...(state[key as REGISTRATION_FORM_KEYS]),
        error: '',
        value
      }
    }))
  );

  render() {
    const { firstName, lastName, email, password, confirmPassword } = this.state;
    const { intl } = this.props;

    return (
      <div className={styles.entrance}>
        <div className={styles.entrance__info}>
          <div className={styles['entrance__info-body']}>
            {/* TODO: add logo */}
            <div className={styles.entrance__logo}>logo</div>
            <div className={styles.entrance__title}>
              {intl.formatMessage(TEXTS.title)}
            </div>
            <div className={styles['entrance__decorate-line']} />
            <div className={styles.entrance__subtitle}>
              {intl.formatMessage(TEXTS.subtitle)}
            </div>
          </div>
        </div>
        <div className={styles.entrance__descr}>
          <div className={styles.entrance__row}>
            <div className={`${styles.entrance__col} ${styles['mb-medium']}`}>
              <Button
                className='std-btn--social-bordered'
                id='sign-in-button'
                text={intl.formatMessage(TEXTS.loginWithFB)}
                svg={<FacebookSvg />}
              />
            </div>
          </div>
          <div className={styles.entrance__row}>
            <div className={`${styles.entrance__col} ${styles['mb-medium']}`}>
              <Button
                className='std-btn--social-bordered'
                id='sign-in-button'
                text={intl.formatMessage(TEXTS.loginWithGoogle)}
                svg={<GoogleSvg />}
              />
            </div>
          </div>
          <div className={styles['entrance__separate-block']}>
            <span>or</span>
          </div>
          <div className={styles.entrance__row}>
            <div className={`${styles.entrance__col} ${styles['entrance__col-6']}`}>
              <Input
                id={REGISTRATION_FORM_KEYS.FIRSTNAME}
                className={firstName.error ? 'std-field__input--error' : 'std-field__input'}
                value={firstName.value}
                onChange={this.onInputChange}
                type='text'
                placeholder={intl.formatMessage(TEXTS.placeholders.firstName)}
                error={firstName.error !== '' ? intl.formatMessage({ id: firstName.error }) : ''}
              />
            </div>
            <div className={`${styles.entrance__col} ${styles['entrance__col-6']}`}>
              <Input
                id={REGISTRATION_FORM_KEYS.LASTNAME}
                className={lastName.error ? 'std-field__input--error' : 'std-field__input'}
                value={lastName.value}
                onChange={this.onInputChange}
                type='text'
                placeholder={intl.formatMessage(TEXTS.placeholders.lastName)}
                error={lastName.error !== '' ? intl.formatMessage({ id: lastName.error }) : ''}
              />
            </div>
          </div>
          <div className={styles.entrance__row}>
            <div className={styles.entrance__col}>
              <Input
                id={REGISTRATION_FORM_KEYS.EMAIL}
                className={email.error ? 'std-field__input--error' : 'std-field__input'}
                value={email.value}
                onChange={this.onInputChange}
                type='text'
                placeholder={intl.formatMessage(TEXTS.placeholders.email)}
                error={email.error !== '' ? intl.formatMessage({ id: email.error }) : ''}
              />
            </div>
          </div>
          <div className={styles.entrance__row}>
            <div className={styles.entrance__col}>
              <Input
                id={REGISTRATION_FORM_KEYS.PASSWORD}
                className={password.error ? 'std-field__input--error' : 'std-field__input'}
                value={password.value}
                onChange={this.onInputChange}
                onClick={this.onShowPasswordClick}
                type={password.showPassword ? 'text' : 'password'}
                placeholder={intl.formatMessage(TEXTS.placeholders.password)}
                svg={password.showPassword ? <OpenEyeSvg /> : <EyeSvg />}
                error={password.error !== '' ? intl.formatMessage({ id: password.error }) : ''}
              />
            </div>
          </div>
          <div className={styles.entrance__row}>
            <div className={styles.entrance__col}>
              <Input
                id={REGISTRATION_FORM_KEYS.CONFIRMATION_PASSWORD}
                className={confirmPassword.error ? 'std-field__input--error' : 'std-field__input'}
                value={confirmPassword.value}
                onChange={this.onInputChange}
                onClick={this.onConfirmPasswordClick}
                type={confirmPassword.showPassword ? 'text' : 'password'}
                placeholder={intl.formatMessage(TEXTS.placeholders.confirmPassword)}
                svg={confirmPassword.showPassword ? <OpenEyeSvg /> : <EyeSvg />}
                error={confirmPassword.error !== '' ? intl.formatMessage({ id: confirmPassword.error }) : ''}
              />
            </div>
          </div>
          <div
            className={`${styles.entrance__row} ${styles['entrance__row--mb-small']}`}
          >
            <div className={styles.entrance__buttons}>
              <Button
                className='std-btn--full-width'
                submit
                id='sign-in-button'
                text={intl.formatMessage(TEXTS.singUp)}
                onClick={this.handleRegistration}
              />
            </div>
          </div>
          <div className={styles.entrance__bottom}>
            <div className={styles.entrance__text}>
              {intl.formatMessage(TEXTS.text.member)}
            </div>
            &nbsp;
            <Link
              className={styles.entrance__link}
              to={localizeRoutePath(intl.locale, intl.formatMessage, AppRoute.Login)}
            >
              {intl.formatMessage(TEXTS.singUp)}
            </Link>
          </div>
          <div className={`${styles['entrance__separate-line']}`}></div>
          <div className={styles.entrance__copyright}>
            <span>By signing up you agree to FlippedNormals’ </span>
            <a href="#">Terms of Service</a>
            {' '}
            and
            {' '}
            <a href="#">Privacy Policy</a>
          </div>
        </div>
      </div>
    );
  }
}
export default injectIntl(Registration)