import decode from 'jwt-decode';
import { AUTH_TOKENS } from 'const';


export class JwtHelper {
  public static set = (key: string, value: string | number) => localStorage.setItem(key, value.toString());
  public static get = (key: string): string => (window.localStorage[key] || '');
  public static delete = () => {
    delete window.localStorage[AUTH_TOKENS.ACCESS];
    delete window.localStorage[AUTH_TOKENS.REFRESH];
    // delete window.localStorage.linkedInReactRedirectUri;
    // delete window.localStorage.linkedInReactState;
  }
};

export const isAuthenticated = (): boolean => {
  let payload = null;
  const token: string = JwtHelper.get(AUTH_TOKENS.ACCESS);

  if (!token) {
    return false;
  }

  try {
    payload = decode(token);
  } catch (e) {
    return false;
  }

  // @ts-ignore
  const { exp } = payload;
  if (exp < new Date().getTime() / 1000) {
    return false;
  }

  return true;
};

export const logout = () => {
  JwtHelper.delete();
  window.location.href = '/';
};
