export default function ArrowNextLeft({ width = '22', height = '22' }: IArrowNextLeft) {
    return (
        <svg
            height={`${height}px`}
            width={`${width}px`}
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg">
            <defs>
                <style>{`.cls-1{fill:#231f20;}`}</style>
            </defs>
            <title />
            <g data-name="Layer 2" id="Layer_2">
                <path
                    className="cls-1"
                    d="M15.12,15.53,25,5.66a1,1,0,0,1,1.41,1.41l-9.06,9.06,8.8,8.8a1,1,0,0,1,0,1.41h0a1,1,0,0,1-1.42,0l-9.61-9.61A.85.85,0,0,1,15.12,15.53Z"
                />
                <path
                    className="cls-1"
                    d="M5.54,15.53l9.88-9.87a1,1,0,1,1,1.41,1.41L7.77,16.13l8.8,8.8a1,1,0,0,1,0,1.41h0a1,1,0,0,1-1.41,0L5.54,16.73A.85.85,0,0,1,5.54,15.53Z"
                />
            </g>
        </svg>
    );
}

interface IArrowNextLeft {
    width?: string;
    height?: string;
}
