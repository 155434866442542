import BaseLayout from 'components/base';
import Profile from 'modules/profile';


export default function ProfilePage() {
    return (
        <BaseLayout>
            <Profile />
        </BaseLayout>
    );
}
