import { connect } from 'react-redux';
import { changeLanguage } from 'modules/i18n/i18n.actions';
import Login from './login.component';
import { login } from './login.actions';


const mapDispatchToProps = {
    changeLanguage,
    login
};

export default connect(null, mapDispatchToProps)(Login);
