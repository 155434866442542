import React from 'react';
import { connect } from 'react-redux';
import { Switch, RouteProps } from 'react-router';
import { useIntl } from 'react-intl';
import { localizeRoutePath } from '../i18n.utils';
import { RootState } from 'store/reducers';

type LocalizedSwitchChildType = RouteProps & {
  isAuthUser: boolean;
}

/**
 * Apply localization to all routes
 * Also checks if all children elements are <Route /> components
 */
const LocalizedSwitchComponent: React.FC<LocalizedSwitchChildType> = ({ children, isAuthUser }) => {
  const { formatMessage, locale } = useIntl();
  return (
    <Switch>
      {React.Children.map(children, (child) => {
        return React.isValidElement<LocalizedSwitchChildType>(child)
          ? React.cloneElement(child, {
            ...child.props,
            // @ts-ignore
            path: localizeRoutePath(locale, formatMessage, child.props.path),
            isAuthUser
          })
          : child;
      }
      )}
    </Switch>
  );
};


const mapStateToProps = ({ user }: RootState) => {
  return ({
    isAuthUser: user.isAuthUser
  })
};

export const LocalizedSwitch = connect(mapStateToProps)(LocalizedSwitchComponent);
