export const INVALID = 'invalid';
export const REQUIRED = 'required';
export const IS_CHECKED = 'is_checked';
export const IS_EMAIL = 'is_email';
export const ON_EMAIL_CONFIRM_CHECKING = 'onEmailConfirmChecking';
export const ON_PASSWORD_CHECKING = 'onPasswordChecking';
export const ON_PASSWORD_CONFIRM_CHECKING = 'onPasswordConfirmChecking';
export const ON_PASSWORD_NOT_EQUALS = 'onPasswordNotEquals';
export const ON_PHONE_CHECKING = 'onPhoneChecking';

export const REG_EXP_NEW_USER_PASSWORD = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
export const REG_EXP_EMAIL = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);
export const REG_EXP_PHONE_NUMBER = new RegExp(/^[+() \d-]+$/);
