import { WrappedComponentProps } from 'react-intl';

export enum REGISTRATION_FORM_KEYS {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRMATION_PASSWORD = 'confirmPassword'
}

export interface IRegistrationProps extends WrappedComponentProps {
  registration: (data: RegistrationPayload) => void;
}

export interface RegistrationPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export interface IRegistrationState {
  [REGISTRATION_FORM_KEYS.FIRSTNAME]: {
    value: string,
    error: string,
    validationList: string[]
  };
  [REGISTRATION_FORM_KEYS.LASTNAME]: {
    value: string,
    error: string,
    validationList: string[]
  };
  [REGISTRATION_FORM_KEYS.EMAIL]: {
    value: string,
    error: string,
    validationList: string[]
  };
  [REGISTRATION_FORM_KEYS.PASSWORD]: {
    value: string,
    error: string,
    showPassword: boolean;
    validationList: string[]
  };
  [REGISTRATION_FORM_KEYS.CONFIRMATION_PASSWORD]: {
    value: string,
    error: string,
    showPassword: boolean;
    validationList: string[]
  };
}
