import React from 'react';
import styles from './index.module.scss';

export default function Dropdown({
  className = '',
  options = [],
  children
}: IDropdown) {
  return (
    <div className={`${styles['std-dropdown']} ${styles[className] || ''}`}>
      <div className={`${styles['std-dropdown__component']}`}>{children}</div>

      <div className={`${styles['std-dropdown__pannel']}`}>
        {options.map((option, key) => <div key={key} className={`${styles['std-dropdown__option']}`}>{option}</div>)}
      </div>
    </div>
  )
}

interface IDropdown {
  className?: string;
  id?: string;
  onChange?: any;
  checked?: any;
  text?: string;
  
  options: React.ReactNode[],
  children: React.ReactNode
}
