import { getRange, getPageInfo } from './paging-algorithm';

function Pagination(props: IPagination) {
  const { limit, currentPage, totalPages, children } = props;

  const _getPageItemProps = (props: GetPageItemPropsType) => {
    const { pageValue, onPageChange: handlePageChange, ...rest } = props;

    const onPageChange = (_e: React.MouseEvent<HTMLElement>) => {
      if (typeof handlePageChange === 'function') {
        handlePageChange(pageValue);
      }
    };

    return {
      onClick: onPageChange,
      ...rest
    };
  };

  const pageInfo = getPageInfo({ limit, totalPages, currentPage });

  const {
    firstPage,
    lastPage,
    hasNextPage,
    hasPreviousPage,
    previousPage,
    nextPage,
  } = pageInfo;

  const pages = totalPages > 0 ? getRange(firstPage, lastPage) : [];

  return (children({
    pages,
    previousPage,
    nextPage,
    totalPages,
    currentPage,
    hasNextPage,
    hasPreviousPage,
    getPageItemProps: _getPageItemProps
  }));
}

type GetPageItemPropsType = { pageValue: number, key?: number, onPageChange: (value: number) => void };
type GetPageItemReturnType = {
  key?: number;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

interface InjectedPaginationProps {
  pages: number[];
  previousPage: number;
  nextPage: number;
  totalPages: number;
  currentPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean,
  getPageItemProps: (props: GetPageItemPropsType) => GetPageItemReturnType;
}

interface IPagination {
  totalPages: number;
  limit: number;
  currentPage: number;
  children(props: InjectedPaginationProps): JSX.Element;
}

export default Pagination;
