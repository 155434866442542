import { LanguageStrings } from './base-strings';

// TODO: just for testing
// @ts-ignore
export const de: LanguageStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.login': '/login',

  /** Page content */
  'home.title': 'Zuhause',
  'home.content':
    'Vielen Dank für den Besuch dieses mehrsprachigen Routing-Beispiels.',
  'login.email': 'Email',
  'login.password': 'Passwort',


  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',
};
