import React from 'react';
import styles from './index.module.scss';

export default function Input({ 
  className = '', 
  id,
  placeholder,
  type = 'text',
  value,
  svg,
  error,
  onChange,
  onClick
} : IInput) {
  return (
    <div className={styles['std-field']}>
      <input
        className={`${styles['std-field__input']} ${styles[className] || ''}`}
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {svg && 
        <div className={styles['std-field__ico']} onClick={onClick}>
          {svg}
        </div>
      }
      { error && 
        <div className={styles['std-field__error']}>
          {error}
        </div>
      }
    </div>
  )
}

interface IInput {
  className?: string;
  id: string;
  error?: string;
  placeholder?: string;
  type: string;
  value: string;
  svg?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onChange: (s: React.ChangeEvent<HTMLInputElement>) => void;
}
