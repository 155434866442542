import React from 'react';
import { AppLanguage } from 'const';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { appStrings } from '..';

export const LanguageSwitcher: React.FC = () => {
  const { pathname } = useLocation();
  const { messages } = useIntl();

  function getMatchingRoute(language: string) {
    /**
     * Get the key of the route the user is currently on
     */
    const route = pathname.substring(3);
    const routeKey = Object.keys(messages).find(key => messages[key] === route);

    /**
     * Find the matching route for the new language
     */
    // @ts-ignore
    const matchingRoute = appStrings[language][routeKey];

    /**
     * Return localized route
     */
    return `/${language}` + matchingRoute;
  }

  // @ts-ignore
  const langText = (l: string) => AppLanguage[l];

  return (
    <ul>
      {Object.keys(AppLanguage).map((lang) => (
        <li key={lang}>
          <NavLink
            // @ts-ignore
            to={getMatchingRoute(AppLanguage[lang])}
          >
            {langText(lang)} 
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
