import {  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from './login.constants';

interface loginState {
    loading : boolean,
    Login_Data ? : any,
  }
  
  const initialState: loginState = {
      loading : false,
  }
  
  const loginReducer = (state : loginState = initialState , action : any) : loginState => {
    switch(action.type) {
      case LOGIN_REQUEST :
        return {
          ...state,
          loading : true
        }
      case LOGIN_SUCCESS :
        return {
          ...state,
          loading: false,
          Login_Data: action.payload,
        };
      case LOGIN_FAILURE :
        return {
          ...state,
          loading : false
        }
      default : return state
    }
  }
  
  export default loginReducer;