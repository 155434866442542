import { combineReducers } from 'redux';
import user from 'modules/user/user.reducer';
import login from 'modules/login/login.reducer';

const rootReducer = combineReducers({
    user,
    login
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
